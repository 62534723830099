var products = {
    proxies: [
        {
            id: "fc1c0b13-fa61-4b9e-b7f5-e86bd8c294b3",
            name: "UK ISP",
            description: "Unparalleled reliability, the performance of datacenter speeds with the success rate of residential proxies.",
            icon: "isp.svg",
            unit: "Proxy",
            units: "Proxies",
            path: "isp",
            title: "ISP Proxies - Primed Proxies",
            details: {
                question: "What is a ISP proxy?",
                answer: "An ISP proxy, short for Internet Service Provider proxy, refers to a type of proxy that utilizes IP addresses supplied by Internet Service Providers. These proxies are valued for their ability to combine the success of residential IPs with the performance often associated with datacenter proxies, making them suitable for both individuals and businesses.",
                keypoints: [
                    {
                        title: "British Telecom ISP",
                        description: "Announced under BT's residential ASN, making the proxies effective on complex websites.",
                        icon: "cloud.svg"
                    },
                    {
                        title: "Ultra High Speed",
                        description: "The proxies are hosted on the most stable and secure infrastructure in London, UK. ",
                        icon: "speed.svg"
                    },
                    {
                        title: "Unlimited Targets",
                        description: "Use our proxies on any site to suit your specific needs.",
                        icon: "target.svg"
                    },
                    {
                        title: "Concurrent Usage",
                        description: "Enjoy no limits on concurrent sessions making scaling up projects easy.",
                        icon: "usage.svg"
                    },
                    {
                        title: "Human-like Scraping",
                        description: "Target websites 99.9% of times detect the proxy as a authentic user.",
                        icon: "user.svg"
                    },
                    {
                        title: "Extended Sessions",
                        description: "Our monthly proxies span for 30 days rolling with no minimum contract term.",
                        icon: "calendar.svg"
                    },
                ],
                footer: [
                    {
                        name: "London, United Kingdom",
                        icon: "location.svg"
                    },
                    {
                        name: "Managed via Dashboard",
                        icon: "dashboard.svg"
                    },
                    {
                        name: "Delivered Instantly",
                        icon: "delivery.svg"
                    }
                ]
            },
        },
        {
            id: "86da73c4-47cc-43cd-a291-b61ec25d33d9",
            name: "Residential",
            description: "Effortlessly collect data with a reliable and vast  residential IP pool which boasts 45 million IPs.",
            icon: "resi.svg",
            unit: "GB",
            units: "GB",
            path: "residential",
            title: "Residential Proxies - Primed Proxies",
            details: {
                question: "What is a Residential proxy?",
                answer: "A residential proxy serves as a middleman server connecting you to a website. Unlike data center proxies, these servers use IP addresses issued by Internet Service Providers (ISPs), and each IP corresponds to an actual desktop device, accurately representing a specific physical location.",
                keypoints: [
                    {
                        title: "45M+ Real IPs",
                        description: "Our residential solution boasts millions of IPs.",
                        icon: "dataflow.svg"
                    },
                    {
                        title: "99.95% Success Rate",
                        description: "On average customers find near certain success rates.",
                        icon: "trophy.svg"
                    },
                    {
                        title: "190+ Locations",
                        description: "Benefit from specific geo-level targeting across an array of countries.",
                        icon: "globe.svg"
                    },
                    {
                        title: "0.5s Response Time",
                        description: "Enjoy low ping speeds due to our high quality residential hosting infrastructure and upstreams.",
                        icon: "time.svg"
                    },
                    {
                        title: "Unlimited Sessions",
                        description: "Generate unlimited proxy address's and use concurrently to one another.",
                        icon: "rocket.svg"
                    },
                    {
                        title: "No expiry",
                        description: "Be flexible with non-expiring proxies and no contracts - use whenever you like.",
                        icon: "calendar.svg"
                    },
                ],
                footer: [
                    {
                        name: "Worldwide Support",
                        icon: "location.svg"
                    },
                    {
                        name: "Managed via Dashboard",
                        icon: "dashboard.svg"
                    },
                    {
                        name: "Delivered Instantly",
                        icon: "delivery.svg"
                    }
                ]
            },
        }
    ],
    servers: [
        {
            id: "9f9fb69d-2f68-4938-a66e-f73a9ef10cc5",
            type: "Basic",
            name: "Server",
            description: "Discover the 'basic' choice that's anything but ordinary and power your needs effortlessly.",
            path: "servers",
            specs: [
                "8 Cores",
                "16GB RAM"
            ],
            details: [
                "50GB SSD",
                "1GBPS Port",
                "AMD EPYC ™ 7413",
                "Windows OS"
            ]
        },
        {
            id: "10b3ffec-d9ce-4e53-b757-b4f860d353ed",
            type: "Advanced",
            name: "Server",
            description: "Step up to the big league with our Advanced server for performance that's truly next-level.",
            path: "servers",
            specs: [
                "16 Cores",
                "32GB RAM"
            ],
            details: [
                "50GB SSD",
                "1GBPS Port",
                "AMD EPYC ™ 7413",
                "Windows OS"
            ]
        }
    ],
    serverDetails: {
        title: "Bare Metal Servers - Primed Proxies",
        description: "Primed servers are physical machines located within our London Data Center. Users can establish connections using Remote Desktop Protocol (RDP) to access these computers virtually.",
        question: "What is a Server?",
        answer: "Primed servers are physical machines located within our London Data Center. Users can establish connections using Remote Desktop Protocol (RDP) to access these computers virtually. This option is ideal for customers who require continuous 24/7 computer availability or the flexibility to connect to a central hub from any location.",
        keypoints: [
            {
                title: "Dedicated",
                description: "Our dedicated root servers offer exceptional performance.",
                icon: "server2.svg"
            },
            {
                title: "Security",
                description: "We ensure the security of your information through our encryption technology.",
                icon: "shield.svg"
            },
            {
                title: "Reliable",
                description: "Never experience downtime with our reliable services up 99.9% of the time.",
                icon: "star.svg"
            },
            {
                title: "Blazing Speeds",
                description: "You can depend on our custom infrastructure to support all your needs.",
                icon: "speed.svg"
            },
        ],
        footer: [
            {
                name: "London, United Kingdom",
                icon: "location.svg"
            },
            {
                name: "Managed via Dashboard",
                icon: "dashboard.svg"
            },
            {
                name: "Delivered Instantly",
                icon: "delivery.svg"
            }
        ]
    },
}

export default products;